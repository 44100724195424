import BackgroundTitle from "../components/BackgroundTitle";
import TopBarIndex from "../components/TopBarIndex";
import img1 from "../assets/img/img-slider-home.jpg";
import InputBilo from "../components/InputBilo";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import { api } from "../conf/apiConf";
import useSWR from "swr";
import React, { useEffect, useState } from "react";
import InputGimlet from "../components/InputGimlet";
import renderHTML from "react-render-html";
import parse from 'html-react-parser';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import GoogleMapContainer from "../Trek/GoogleMapContainer";
import MapContainer from "../Trek/MapContainer";
import pdf from "../assets/imgTrek/pdf.svg";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import auto from "../assets/imgTrek/auto.svg";
import tenda from "../assets/imgTrek/tenda.svg";
import clock from "../assets/imgTrek/clock.svg";
import { Rating, Alert, Dialog, DialogContent, DialogTitle, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import { useSelector } from "react-redux";
import InputCustom from "../components/InputCustom";
import Login from "../Auth/Login";
import DialogCustom from "../components/DialogCustom";
import env from "../conf/variables";
import styles from "../Trek/Trek.module.css"

const ItemImageTrek = ({ title, data, img, voto }) => {
    const StyledRating = styled(Rating)({
        "& .MuiRating-iconFilled": {
            color: "#796557",
        },
    });
}

const SingleCourse = () => {
    const [course, setCourse] = useState(null)
    const [quote, setQuote] = useState(null)
    const [monthName, setMonthName] = useState('')
    const [imgUrl, setImgUrl] = useState('')
    const [imgUrlIndex, setImgUrlIndex] = useState(0)
    const [openIscriviti, setOpenIscriviti] = useState(false)
    const [openPrenotati, setOpenPrenotati] = useState(false)
    const [idMultiIscrizioni, setIdMultiIscrizioni] = useState([])
    const [usersFound, setUsersFound] = useState([])
    const [openPaymentMethod, setOpenPaymentMethod] = useState(false)
    const { id } = useParams()
    const navigate = useNavigate()
    const count = useSelector(state => state.count)
    const [searchUser, setSearchUser] = useState({
        nome: '',
        cognome: '',
        email: '',
        codice_fiscale: ''
    })
    const [error, setError] = useState(
        {
            open: false,
            error: ''
        }
    )
    const [success, setSuccess] = useState(
        {
            open: false,
            message: ''
        }
    )
    const [openFormSearchUser, setOpenFormSearchUser] = useState(false)
    const [userSelected, setUserSelected] = useState(null)
    const location = useLocation()

    api.setHeaders({
        Authorization: "Bearer " + count.token,
    });

    const fetcher = (url) => api.get(url).then((res) => {
        setCourse(res.data.data)
        api.get(`/courses/get-quote/${res.data.data.id}`).then((result) => {
            setQuote(result.data.data)
            return result
        });
        return res
    });
    const { data: courseObj } = useSWR([`/courses/get-corsi/${id}`], fetcher);


    function getNameMonth(monthIndex) {
        let name = 'Gen'
        switch (monthIndex) {
            case 0:
                name = 'Gen';
                break;
            case 1:
                name = 'Feb';
                break;
            case 2:
                name = 'Mar';
                break;
            case 3:
                name = 'Apr';
                break;
            case 4:
                name = 'Mag';
                break;
            case 5:
                name = 'Giu';
                break;
            case 6:
                name = 'Lug';
                break;
            case 7:
                name = 'Ago';
                break;
            case 8:
                name = 'Set';
                break;
            case 9:
                name = 'Ott';
                break;
            case 10:
                name = 'Nov';
                break;
            case 11:
                name = 'Dec';
                break;

            default:
                break;
        }

        setMonthName(name)
    }

    function handleClickedImg(e, index) {
        setImgUrl(course && course.other_image ? course.other_image[index].image : '')
        setImgUrlIndex(index)
    }

    function handleNavigationGalleryButton(e, to) {
        let newIndex = imgUrlIndex + to
        if (newIndex < 0) {
            newIndex = 0
        }
        if (newIndex > course && course.other_image ? course.other_image.length : 0) {
            newIndex = course && course.other_image ? (course.other_image.length - 1) : 0
        }
        setImgUrl(course && course.other_image ? course.other_image[newIndex].image : '')
        setImgUrlIndex(newIndex)
    }

    useEffect(() => {
        getNameMonth(new Date(course ? course.data_start : '').getMonth())
        setImgUrl(course && course.other_image ? course.other_image[0].image : '')
        setImgUrlIndex(0)
    }, [course])

    function handleClickPagamento(payment_method = '') {
        api.post('subscriptions/add-subscription-without-payment', {
            user_id: count.profile.id,
            id: course ? course.course_subscription_id : '',
            type: 'course',
            userIDs: idMultiIscrizioni.map(user => user.id),
            balance: course?.quote_request,
            payment_method: payment_method
        }).then(res => {
            if (res.data.errors) {
                setError({
                    open: true,
                    error: res.data.errors.user[0]
                })
            } else {
                setSuccess({
                    open: true,
                    message: 'La informiamo che ha effettuato correttamente l\'iscrizione'
                })
            }

            return res
        })
        setOpenIscriviti(false);
    }

    return (
        <>
            <TopBarIndex />
            <BackgroundTitle title={course ? course.title : ''} img={img1} position={"center"} />
            <div
                className={" bg-white relative z-40 p-8 pt-20 flex flex-col"}
                style={{ fontFamily: "bilo" }}
            >
                <div className={"flex flex-row items-center gap-1"}>
                    <div
                        className={"cursor-pointer hover:opacity-50"}
                    >
                        Home {">"}
                    </div>
                    <div>Corsi {">"}</div>
                    <div className={"font-bold"}>{course ? course.title : ''}</div>
                </div>
                <div className="flex my-5">
                    <div className="bg-[#F1E9E4] aspect-square w-[4rem]">
                        <InputGimlet title={course ? new Date(course.data_start).getDate() : ''} fontStyle="italic" fontWeight={700} className={'text-3xl text-center'} />
                        <InputGimlet title={monthName} fontStyle="italic" fontWeight={500} className={'text-2xl text-center'} />
                    </div>
                    <div className="ml-2">
                        <InputGimlet title={'Sezione'} fontStyle="italic" fontWeight={500} className={'text-2xl text-[#DF5B23]'} />
                        <InputGimlet title={course ? course.dname : ''} fontStyle="italic" fontWeight={700} className={'text-3xl text-[#DF5B23]'} />
                    </div>
                </div>
                <InputGimlet title={course ? course.title : ''} fontStyle="italic" fontWeight={700} className={'text-3xl text-[#DF5B23] mb-2'} />
                <InputGimlet title={course ? course.subtitle : ''} fontStyle="italic" fontWeight={500} className={'text-2xl text-[#DF5B23] mb-2'} />
                {course && course.hours_start ?
                    <div className="mt-5">
                        <InputBilo
                            className={'text-center text-2xl inline'}
                            title={'ORARIO DI INIZIO: '}
                            fontWeight={700}
                        />
                        <InputBilo
                            className={'text-center text-2xl inline'}
                            title={course['hours_start'].split('T')[1].split(':')[0] + ':' + course['hours_start'].split('T')[1].split(':')[1]}

                        />
                    </div>
                    : ''}
                {course && course.hours_end ?
                    <div className="my-5">
                        <InputBilo
                            className={'text-center text-2xl inline'}
                            title={'ORARIO DI FINE: '}
                            fontWeight={700}
                        />
                        <InputBilo
                            className={'text-center text-2xl inline'}
                            title={course['hours_end'].split('T')[1].split(':')[0] + ':' + course['hours_end'].split('T')[1].split(':')[1]}
                        />
                    </div>
                    : ''}
                <div className={"inline " + styles['prova']}>
                    {
                        parse(course && course.description ? course.description : '')
                    }
                </div>
                {
                    course && course.course_poster_path &&
                    <div className="my-5">
                        <InputBilo
                            className={'text-xl inline'}
                            title={'Scarica la locandina del corso '}
                        />
                        <Link
                            className={'text-xl text-[#DF5B23] underline inline hover:opacity-80 hover:cursor-pointer font-bold'}
                            to={course.course_poster_path}>
                            Qui
                        </Link>
                    </div>
                }

                {
                    course && course.other_image ?
                        <>
                            <div className="h-[630px] w-full rounded-xl relative">
                                <div onClick={(e) => { handleNavigationGalleryButton(e, -1) }} className="absolute top-1/2 -translate-y-1/2 left-[10px]">{'<'}-</div>
                                <img className="rounded-xl h-full w-full object-cover" src={imgUrl} alt="" />
                                <div onClick={(e) => { handleNavigationGalleryButton(e, 1) }} className="absolute top-1/2 -translate-y-1/2 right-[10px]">-{'>'}</div>
                            </div>
                            <div className="flex justify-center gap-2 items-center">
                                {course.other_image.map((img, index) => {
                                    return <div onClick={(e) => { handleClickedImg(e, index) }} className="rounded-lg aspect-square w-[4rem]">
                                        <img className="rounded-xl h-full w-full object-cover" src={img.image} alt="" />
                                    </div>
                                })}
                            </div>
                        </>
                        : ''
                }

                {
                    course && course.tutor ?
                        <>
                            <InputGimlet
                                title={'Tutors'}
                                fontStyle="italic"
                                className={'text-2xl text-[#DF5B23]'}
                            />
                            {
                                course.tutor.map((tutor, index) => {
                                    return <div key={index}>
                                        <InputBilo
                                            title={tutor.name && tutor.surname ? tutor.name + ' ' + tutor.surname : ''}
                                            fontWeight={700}
                                            className={'text-xl inline'}
                                        />
                                        <InputBilo
                                            title={tutor.description ? tutor.description : ''}
                                            fontWeight={700}
                                            className={'text-xl inline'}
                                        />
                                    </div>
                                })
                            }
                        </>
                        : ''
                }

                {
                    course && course.referenti_esterni ?
                        <>
                            <InputGimlet
                                title={'Referenti esterni '}
                                fontStyle="italic"
                                className={'text-2xl text-[#DF5B23]'}
                            />
                            {
                                course.referenti_esterni.map((referente, index) => {
                                    return <div key={index}>
                                        <InputBilo
                                            title={referente.name && referente.surname ? referente.name + ' ' + referente.surname : ''}
                                            fontWeight={700}
                                            className={'text-xl inline'}
                                        />
                                        <InputBilo
                                            title={referente.referente_esterno_description}
                                            fontWeight={700}
                                            className={'text-xl inline'}
                                        />
                                    </div>
                                })
                            }
                        </>
                        : ''
                }

                {
                    course && course.referenti_interni ?
                        <>
                            <InputGimlet
                                title={'Referenti interni'}
                                fontStyle="italic"
                                className={'text-2xl text-[#DF5B23]'}
                            />
                            {
                                course.referenti_interni.map((referente, index) => {
                                    return <div key={index}>
                                        <InputBilo
                                            title={referente.name && referente.surname ? referente.name + ' ' + referente.surname : ''}
                                            fontWeight={700}
                                            className={'text-xl inline'}
                                        />
                                        <InputBilo
                                            title={referente.referente_interno_description}
                                            fontWeight={700}
                                            className={'text-xl inline'}
                                        />
                                    </div>
                                })
                            }
                        </>
                        : ''
                }

                <div className="flex flex-col lg:flex-row justify-between gap-2 align my-5">
                    <div className={'w-full max-w-[750px]'}>
                        <div className="p-2 bg-[#e7ded5] pt-6">
                            <div className="flex flex-col [390px]:flex-row items-center gap-2">
                                <div
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: "bold",
                                        fontStyle: "normal",
                                    }}
                                    className={"inline-flex text-3xl text-[#796557]"}>
                                    QUOTA DI PARTECIPAZIONE{" "}
                                </div>
                                <div
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: "bold",
                                        fontStyle: "normal",
                                    }}
                                    className={"text-3xl  text-[#DF5B23]"}>
                                    €
                                    {course ? course.quote_request : ''}
                                </div>
                            </div>
                            {/* <div className={"underline text-[#796557] text-2xl"}>
                                *leggi le note sulla composizione delle quota
                            </div> */}
                        </div>
                        <div className="p-2 pb-6 bg-[#f2ede8]">
                            <div className={"flex flex-row items-center"}>
                                <FiberManualRecordIcon sx={{ color: "#796557", width: 10 }} />
                                <div
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: 500,
                                        fontStyle: "normal",
                                    }}
                                    className={"ml-2 text-2xl  text-[#796557]"}>
                                    Tipo d'iscrizione:{" "}
                                    <div
                                        style={{
                                            fontFamily: "bilo",
                                            fontWeight: "bold",
                                            fontStyle: "normal",
                                        }}
                                        className={"inline-flex"}>
                                        {course && course.type_enrollment ? course.type_enrollment.slice(0, 1).toUpperCase() + course.type_enrollment.replace('_', ' ').slice(1) : ''}
                                    </div>
                                </div>
                            </div>
                            <div className={"flex flex-row items-center"}>
                                <FiberManualRecordIcon sx={{ color: "#796557", width: 10 }} />
                                <div
                                    style={{
                                        fontFamily: "bilo",
                                        fontWeight: 500,
                                        fontStyle: "normal",
                                    }}
                                    className={"ml-2 text-2xl  text-[#796557]"}>
                                    Modalità d'iscrizione:{" "}
                                    <div
                                        style={{
                                            fontFamily: "bilo",
                                            fontWeight: "bold",
                                            fontStyle: "normal",
                                        }}
                                        className={"inline-flex"}>
                                        {course ? course.registration_methods.slice(0, 1).toUpperCase() + course.registration_methods.slice(1) : ''}
                                    </div>
                                </div>
                            </div>

                            <div
                                style={{
                                    fontFamily: "bilo",
                                    fontWeight: "bold",
                                    fontStyle: "normal",
                                }}
                                className={"text-2xl text-[#DF5B23] mt-12"}>
                                {course ? course.available_seats > 0 ? 'Posti disponibili: ' + course.available_seats : "Abbiamo raggiunto il numero massimo di iscritti" : ''}
                            </div>

                        </div>
                        <div
                            style={{
                                fontFamily: "bilo",
                                fontWeight: "bold",
                                fontStyle: "normal",
                            }}
                            className={"text-2xl text-[#796557] pl-2 bg-[#f2ede8]"}>
                            Per ulteriori informazioni contatta la segreteria di Trekking
                            Italia
                        </div>
                        <div
                            style={{
                                fontFamily: "bilo",
                                fontWeight: 500,
                                fontStyle: "normal",
                            }}
                            className={"text-2xl text-[#796557] p-2 pb-6 bg-[#f2ede8]"}>
                            {course ? course.email_department : ''}
                        </div>
                        {
                            course && course.users_subscribers && (count?.profile?.id in course.users_subscribers) ?
                            <div className="pl-2 pb-2 w-full flex items-center flex-col md:flex-row bg-[#f2ede8] gap-2">
                                <h1 className="py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit ">
                                    {
                                        course.users_subscribers[count?.profile?.id] === 'subscriber' || course.users_subscribers[count?.profile?.id] === 'complete' ?
                                            'Sei già iscritto'
                                            : (
                                                course.users_subscribers[count?.profile?.id] === 'reservation' ?
                                                    'Iscrizione in corso'
                                                    :
                                                    'Sei in lista d\'attesa'
                                            )
                                    }
                                </h1>
                            </div>
                            :
                            <div className="pl-2 pb-2 w-full flex items-center flex-col md:flex-row bg-[#f2ede8] gap-2">
                            {
                                course && course.add_subscriber ?
                                    <button
                                        onClick={() => { setOpenIscriviti(true) }}
                                        className={"py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                        style={{
                                            fontFamily: "gimlet-text",
                                            fontWeight: 500,
                                            fontStyle: "italic",
                                        }}
                                    >
                                        ISCRIVITI
                                    </button>
                                    : ''
                            }
                            {
                                course && course.add_reservation ?
                                    <button
                                        onClick={() => { setOpenPrenotati(true) }}
                                        className={"py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                        style={{
                                            fontFamily: "gimlet-text",
                                            fontWeight: 500,
                                            fontStyle: "italic",
                                        }}
                                    >
                                        PRENOTATI
                                    </button>
                                    : ''
                            }
                            {
                                course && course.add_waiting_list ?
                                    <button
                                        className={"py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                        style={{
                                            fontFamily: "gimlet-text",
                                            fontWeight: 500,
                                            fontStyle: "italic",
                                        }}
                                    >
                                        LISTA D'ATTESA
                                    </button>
                                    : ''
                            }
                        </div>
                        }
                        
                    </div>
                    <div className="mt-4 lg:mt-0 h-[40vh] lg:h-[200px] w-full max-w-[750px] lg:max-w-[600px] lg:w-[75%]">
                        {
                            course ? <MapContainer details={{
                                latitude_end: course.latitude_start,
                                longitude_end: course.longitude_start,
                            }} /> : ''
                        }
                    </div>
                </div>

                <div className={"flex flex-col"}>
                    <div
                        style={{ fontFamily: "gimlet-text" }}
                        className={"text-2xl mt-20 mb-8 text-[#DF5B23]"}>
                        PROGRAMMA GIORNALIERO
                    </div>
                    <Swiper
                        style={{
                            "--swiper-navigation-color": "#796557",
                        }}
                        navigation={true}
                        slidesPerView={1}
                        modules={[Navigation]}
                        className="mySwiper">
                        {course && course.details ?
                            course.details.map(
                                (day, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <SwiperSlide key={index + Math.random()}>
                                                <div className={"h-full w-full"}>
                                                    <div
                                                        className={
                                                            "lg:h-28 h-36 w-full bg-[#BBC045] gap-4 flex items-center"
                                                        }>
                                                        <div
                                                            className={
                                                                "w-fit bg-white lg:text-2xl text-xl rounded-2xl text-[#796557] p-4 lg:m-4 lg:ml-12 ml-4 items-center"
                                                            }
                                                            style={{ fontFamily: "gimlet-text" }}>
                                                            GIORNO {index + 1}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            "flex flex-col w-full justify-center items-center mt-2 border-2 border-[#f1e9e4]"
                                                        }>
                                                        <div
                                                            className={
                                                                "font-bold lg:text-4xl text-xl text-[#796557] mt-8"
                                                            }>
                                                            {day.title}
                                                        </div>
                                                        <div
                                                            className={
                                                                "lg:text-2xl uppercase text-xl text-[#796557]"
                                                            }>
                                                            {day.day_type && day.day_type}
                                                        </div>
                                                        <div
                                                            className={
                                                                `w-full p-10 grid grid-cols-1 ${/* day.locality_check && 'sm:grid-cols-2' */ ''} items-center grid-rows-${day.tutor_check + day.external_contact_check + day.internal_contact_check + /* day.locality_check */ + 1} sm:grid-rows-${day.tutor_check + day.external_contact_check + day.internal_contact_check + 1}`
                                                            }>
                                                            <div className="justify-self-center col-start-1 row-start-1 col-span-1 row-span-1">
                                                                <div className="flex whitespace-pre">
                                                                    <InputGimlet

                                                                        title={'Giorno: '}
                                                                    />
                                                                    <InputGimlet
                                                                        title={day && day.date ? `${day.date.split('-')[2]}-${day.date.split('-')[1]}-${day.date.split('-')[0]}` : ''}
                                                                        fontWeight={700}
                                                                        fontStyle="italic"

                                                                    />
                                                                </div>
                                                                <div className="flex whitespace-pre my-4">
                                                                    <InputGimlet

                                                                        title={'Ora di inizio: '}
                                                                    />
                                                                    <InputGimlet
                                                                        title={day && day.hours_start ? `${day.hours_start.split(':')[0]}:${day.hours_start.split(':')[1]}` : ''}
                                                                        fontWeight={700}
                                                                        fontStyle="italic"

                                                                    />
                                                                </div>
                                                                <div className="flex whitespace-pre">
                                                                    <InputGimlet

                                                                        title={'Ora di fine: '}
                                                                    />
                                                                    <InputGimlet
                                                                        title={day && day.hours_end ? `${day.hours_end.split(':')[0]}:${day.hours_start.split(':')[1]}` : ''}
                                                                        fontWeight={700}
                                                                        fontStyle="italic"

                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="justify-self-center col-start-1 row-start-2 col-span-1 row-span-1">
                                                                {
                                                                    day.tutors && day.tutor_check ? <>
                                                                        <InputGimlet title={'Tutors aggiuntivi'} />
                                                                        {day.tutors.map((tutor, index) => {
                                                                            return <React.Fragment key={index}>
                                                                                <InputGimlet
                                                                                    title={tutor.name && tutor.surname ? tutor.name + ' ' + tutor.surname : ''}
                                                                                    fontWeight={700}
                                                                                    fontStyle="italic" />
                                                                            </React.Fragment>
                                                                        })}
                                                                    </>
                                                                        : ''
                                                                }
                                                            </div>
                                                            <div className={`justify-self-center col-start-1 row-start-${day.tutor_check ? 3 : 2} col-span-1 row-span-1`}>
                                                                {
                                                                    day.external_contacts && day.external_contact_check ? <>
                                                                        <InputGimlet title={'Referenti esterni aggiuntivi'} />
                                                                        {day.external_contacts.map((external, index) => {
                                                                            return <React.Fragment key={index}>
                                                                                <InputGimlet
                                                                                    title={external.name && external.surname ? external.name + ' ' + external.surname : ''}
                                                                                    fontWeight={700}
                                                                                    fontStyle="italic" />
                                                                            </React.Fragment>
                                                                        })}
                                                                    </>
                                                                        : ''
                                                                }
                                                            </div>
                                                            <div className={`justify-self-center col-start-1 row-start-${day.tutor_check + day.external_contact_check + 2} col-span-1 row-span-1`}>
                                                                {
                                                                    day.internal_contacts && day.internal_contact_check ? <>
                                                                        <InputGimlet title={'Referenti interni aggiuntivi'} />
                                                                        {day.internal_contacts.map((internal, index) => {
                                                                            return <React.Fragment key={index}>
                                                                                <InputGimlet
                                                                                    title={internal.name && internal.surname ? internal.name + ' ' + internal.surname : ''}
                                                                                    fontWeight={700}
                                                                                    fontStyle="italic" />
                                                                            </React.Fragment>
                                                                        })}
                                                                    </>
                                                                        : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            "text-left w-full flex mt-2 flex-col bg-[#f1e9e4] p-4"
                                                        }>
                                                        <div
                                                            className={"text-[#796557] text-2xl font-bold"}>
                                                            Argomento
                                                        </div>
                                                        <div className={"text-2xl text-[#796557]"}>
                                                            <div className={"text-[#796557]"}>
                                                                <div className={"inline " + styles['prova']}>
                                                                    {parse(day && day.subject ? day.subject : '')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </React.Fragment>
                                    );
                                }
                            ) : ''}
                    </Swiper>
                </div>


            </div>
            <FooterTop />
            <FooterDown />
            {
                count && count.profile ?
                    <>
                        {/* ISCRIVITI */}
                        <Dialog
                            open={openIscriviti}
                            onClose={() => {
                                setOpenIscriviti(false);
                            }}
                            maxWidth={"lg"}
                            fullWidth={true}>
                            <>
                                <DialogTitle>
                                    Iscrizione
                                </DialogTitle>
                                <DialogContent>
                                    <div className="grid grid-cols-2 gap-4 border-2 pr-[95px]">
                                        <div className="flex">
                                            <InputBilo
                                                title={`Nome: ${count.profile.name}`}
                                                className={'text-lg'} />
                                        </div>
                                        <div className="flex">
                                            <InputBilo
                                                title={`Cognome: ${count.profile.surname}`}
                                                className={'text-lg'} />
                                        </div>
                                        <div className="flex">
                                            <InputBilo
                                                title={`email: ${count.profile.email}`}
                                                className={'text-lg'} />
                                        </div>
                                        <div className="flex">
                                            <InputBilo
                                                title={`Codice Fiscale: ${count.profile.fiscal_code}`}
                                                className={'text-lg'} />
                                        </div>
                                    </div>
                                    {
                                        idMultiIscrizioni && idMultiIscrizioni.map((user, index) => {
                                            return (
                                                <div className="flex gap-2 items-center">
                                                    <div key={index} className="w-full mt-2 grid grid-cols-2 gap-4 border-2">
                                                        <div className="flex">
                                                            <InputBilo
                                                                title={`Nome: ${user.nome}`}
                                                                className={'text-lg'} />
                                                        </div>
                                                        <div className="flex">
                                                            <InputBilo
                                                                title={`Cognome: ${user.surname}`}
                                                                className={'text-lg'} />
                                                        </div>
                                                        <div className="flex">
                                                            <InputBilo
                                                                title={`email: ${user.email}`}
                                                                className={'text-lg'} />
                                                        </div>
                                                        <div className="flex">
                                                            <InputBilo
                                                                title={`Codice Fiscale: ${user.codice_fiscale}`}
                                                                className={'text-lg'} />
                                                        </div>
                                                    </div>
                                                    <button
                                                        onClick={() => {
                                                            setIdMultiIscrizioni(prevState => {
                                                                return prevState.filter((value, indexPrev) => {
                                                                    return indexPrev !== index
                                                                })
                                                            })
                                                        }}
                                                        className={"mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                        style={{
                                                            fontFamily: "gimlet-text",
                                                            fontWeight: 500,
                                                            fontStyle: "italic",
                                                        }}>
                                                        X
                                                    </button>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="mt-5">
                                        <button
                                            onClick={() => { setOpenFormSearchUser(true) }}
                                            className={"mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                            style={{
                                                fontFamily: "gimlet-text",
                                                fontWeight: 500,
                                                fontStyle: "italic",
                                            }}>
                                            + Aggiungi Utente
                                        </button>
                                    </div>
                                    {openFormSearchUser &&
                                        <>
                                            <div className="mb-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 w-full items-end gap-4 mt-5">
                                                <InputCustom
                                                    name={"nome"}
                                                    value={searchUser.nome}
                                                    onChange={(e) => setSearchUser((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            nome: e.target.value
                                                        }
                                                    })}
                                                    title={"Nome*"}
                                                    fullWidth
                                                />
                                                <InputCustom
                                                    name={"cognome"}
                                                    value={searchUser.cognome}
                                                    onChange={(e) => setSearchUser((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            cognome: e.target.value
                                                        }
                                                    })}
                                                    title={"cognome*"}
                                                    fullWidth
                                                />
                                                <InputCustom
                                                    name={"email"}
                                                    value={searchUser.email}
                                                    onChange={(e) => setSearchUser((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            email: e.target.value
                                                        }
                                                    })}
                                                    title={"Email"}
                                                    fullWidth
                                                />
                                                <InputCustom
                                                    name={"fiscal_code"}
                                                    value={searchUser.codice_fiscale}
                                                    onChange={(e) => setSearchUser((prevState) => {
                                                        return {
                                                            ...prevState,
                                                            codice_fiscale: e.target.value
                                                        }
                                                    })}
                                                    title={"Codice Fiscale"}
                                                    fullWidth
                                                />
                                                <button
                                                    onClick={() => {
                                                        setUsersFound([])
                                                        api.post('search-user-for-subscription', searchUser).then((res) => {
                                                            setUsersFound(res.data.data.map((user) => {
                                                                return {
                                                                    nome: user.name,
                                                                    surname: user.surname,
                                                                    email: user.email,
                                                                    codice_fiscale: user.fiscal_code,
                                                                    id: user.id
                                                                }
                                                            }))
                                                            return res
                                                        });
                                                    }}
                                                    type={"button"}
                                                    className={"h-[50px] py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                    style={{
                                                        fontFamily: "gimlet-text",
                                                        fontWeight: 500,
                                                        fontStyle: "italic",
                                                    }}>
                                                    CERCA
                                                </button>
                                            </div>
                                            {
                                                usersFound.length > 0 &&
                                                <div className="flex gap-2">
                                                    <InputCustom
                                                        id="select_user"
                                                        select
                                                        fullWidth
                                                        name={"id_user"}
                                                        sx={{
                                                            '& .MuiSelect-select': {
                                                                display: 'flex',
                                                                gap: 2
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            setUserSelected(e.target.value)
                                                        }}
                                                    >
                                                        {usersFound.map((user, index) => {
                                                            return (
                                                                <MenuItem key={index} value={user} className="w-full p-2 gap-2 flex justiify-center">
                                                                    <InputBilo
                                                                        className={'whitespace-pre'}
                                                                        title={user.nome}
                                                                    />
                                                                    <InputBilo
                                                                        className={'whitespace-pre'}
                                                                        title={user.surname}
                                                                    />
                                                                    <InputBilo
                                                                        className={'whitespace-pre'}
                                                                        title={user.email}
                                                                    />
                                                                    <InputBilo
                                                                        className={'whitespace-pre'}
                                                                        title={user.codice_fiscale}
                                                                    />
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </InputCustom>
                                                    <button
                                                        onClick={() => {
                                                            setIdMultiIscrizioni(prevState => {
                                                                if (prevState.some((value) => { return value.id === userSelected.id })) {
                                                                    return [...prevState]
                                                                } else {
                                                                    return [
                                                                        ...prevState,
                                                                        userSelected
                                                                    ]
                                                                }
                                                            })
                                                            setOpenFormSearchUser(false)
                                                        }}
                                                        type={"button"}
                                                        className={"h-[50px] py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                        style={{
                                                            fontFamily: "gimlet-text",
                                                            fontWeight: 500,
                                                            fontStyle: "italic",
                                                        }}>
                                                        Salva
                                                    </button>
                                                </div>
                                            }
                                        </>

                                    }

                                    {
                                        course ? (
                                            course.quote_request != 0 ?
                                                <button
                                                    className={"mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                    style={{
                                                        fontFamily: "gimlet-text",
                                                        fontWeight: 500,
                                                        fontStyle: "italic",
                                                    }}
                                                    onClick={() => { setOpenIscriviti(false); setOpenPaymentMethod(true) }}>
                                                    Iscrivi
                                                </button>
                                                :
                                                <button
                                                    className={"mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                                    style={{
                                                        fontFamily: "gimlet-text",
                                                        fontWeight: 500,
                                                        fontStyle: "italic",
                                                    }}
                                                    onClick={() => { handleClickPagamento() }}>
                                                    Iscrivi
                                                </button>
                                        )
                                            : ''
                                    }

                                </DialogContent>
                            </>

                        </Dialog>
                        {/* SCEGLI METODO DI PAGAMENTO */}
                        <DialogCustom
                            open={openPaymentMethod}
                            title={'Scegli'}
                            content={
                                <>
                                    <InputBilo className={'text-2xl'} title={`Importo: €${course ? `${(Number(course.quote_request) + (Number(course.quote_request) * idMultiIscrizioni.length)).toFixed(2)}` : ''} `} />
                                    <InputBilo className={'text-xl'} title={env.borsellinoFunction ? "Preferisci pagare con il borsellino elettronico o con Carta?" : 'Con cosa vuoi pagare?'} />
                                </>
                            }
                            handleClose={() => {
                                api.post(`subscriptions/prepareWalletSubscription`, {
                                    user_id: count.profile.id,
                                    id: course ? course.course_subscription_id : '',
                                    type: 'course',
                                    balance: course ? `${(Number(course.quote_request) + (Number(course.quote_request) * idMultiIscrizioni.length)).toFixed(2)}` : '',
                                    userIDs: idMultiIscrizioni.map(user => user.id)
                                }).then((res) => {
                                    localStorage.setItem('lastpage', location.pathname)
                                    if (res && res.data && res.data.data) {
                                        let a = document.createElement("a");
                                        document.body.appendChild(a);
                                        a.style = "display: none";
                                        a.href = res.data.data;
                                        a.target = '_blank'
                                        a.click();
                                        document.body.removeChild(a);
                                    } else {
                                        setError({
                                            open: true,
                                            error: res && res.data && res.data.errors ? res.data.errors[Object.keys(res.data.errors)[0]][0] : ''
                                        })
                                    }
                                    return res
                                });
                                setOpenPaymentMethod(false)
                            }}
                            closeModal={() => {
                                setOpenPaymentMethod(false)
                            }}
                            handleClose2={() => {
                                api.post(`subscriptions/requestWithoutWallet`, {
                                    user_id: count.profile.id,
                                    id: course ? course.course_subscription_id : '',
                                    type: 'course',
                                    balance: course ? `${(Number(course.quote_request) + (Number(course.quote_request) * idMultiIscrizioni.length)).toFixed(2)}` : '',
                                    userIDs: idMultiIscrizioni.map(user => user.id)
                                }).then((res) => {
                                    localStorage.setItem('lastpage', location.pathname)
                                    if (res && res.data && res.data.data) {
                                        window.open(res.data.data)
                                    } else {
                                        setError({
                                            open: true,
                                            error: res && res.data && res.data.errors ? res.data.errors[Object.keys(res.data.errors)[0]][0] : ''
                                        })
                                    }
                                    return res
                                });

                                setOpenPaymentMethod(false)
                            }}
                            button={env.borsellinoFunction ? "Borsellino elettronico" : null}
                            button2={'Carta'}
                        />
                        {/* ERROR ISCRIVITI */}
                        <DialogCustom
                            open={error.open}
                            title={'Errore'}
                            content={
                                <>
                                    <InputBilo className={'text-xl'} title={error.error} />
                                </>
                            }
                            button={'Chiudi'}
                            handleClose={() => {
                                (setError(prevState => {
                                    return {
                                        open: false,
                                        error: ''
                                    }
                                }))
                            }}
                            closeModal={() => {
                                (setError(prevState => {
                                    return {
                                        open: false,
                                        error: ''
                                    }
                                }))
                            }}
                        />

                        {/* PRENOTATI */}
                        <DialogCustom
                            open={openPrenotati}
                            title={'Prenotati'}
                            content={
                                <>
                                    <InputBilo className={'text-xl'} title={
                                        'Sicuro di volerti iscrivere a questo corso?'
                                    } />
                                </>
                            }
                            button={'No'}
                            button2={'Si'}
                            handleClose={() => {
                                setOpenPrenotati(false)
                            }}
                            closeModal={() => {
                                setOpenPrenotati(false)
                            }}
                            handleClose2={() => {
                                api.post('subscriptions/add-reservation', {
                                    user_id: count.profile.id,
                                    id: course ? course.course_subscription_id : '',
                                    type: 'course',
                                    balance: course ? `${(Number(course.quote_request) + (Number(course.quote_request) * idMultiIscrizioni.length)).toFixed(2)}` : '',
                                }).then(res => {
                                    if (res.data.errors) {
                                        setError({
                                            open: true,
                                            error: res.data.errors.user[0]
                                        })
                                    } else {
                                        setSuccess({
                                            open: true,
                                            message: 'La informiamo che la sua prenotazione è andata a buon fine'
                                        })
                                    }

                                    return res
                                })


                                setOpenPrenotati(false)
                            }}
                        />
                        {/* Success avvenuta prenotazione/riservazione */}
                        <Dialog
                            open={success.open}
                            onClose={() => {
                                setSuccess({
                                    open: false,
                                    message: ''
                                });
                            }}
                            maxWidth={"lg"}
                            fullWidth={true}>
                            <DialogTitle>
                                Prenotazione effettuata
                            </DialogTitle>
                            <DialogContent>
                                <InputBilo
                                    className={'text-2xl'}
                                    title={success.message}
                                />
                                <button
                                    onClick={() => {
                                        setSuccess({
                                            open: false,
                                            message: ''
                                        })
                                    }}
                                    className={"py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                                    style={{
                                        fontFamily: "gimlet-text",
                                        fontWeight: 500,
                                        fontStyle: "italic",
                                    }}>
                                    Chiudi
                                </button>
                            </DialogContent>
                        </Dialog>
                    </>


                    :
                    <Dialog
                        open={openIscriviti || openPrenotati}
                        onClose={() => {
                            setOpenIscriviti(false);
                            setOpenPrenotati(false);
                        }}
                        maxWidth={"lg"}
                        fullWidth={true}>
                        <>
                            <DialogTitle>
                                Per visualizzare questo contenuto devi effettuare l’accesso
                            </DialogTitle>
                            <DialogContent>
                                <Login loginTo={`/corsi/${id}`} />
                            </DialogContent>
                        </>

                    </Dialog>
            }
        </>
    );
};

export default SingleCourse;
