import React from "react";
import TopBarIndex from "../components/TopBarIndex";
import FooterTop from "../Sezioni/FooterTop";
import FooterDown from "../Sezioni/FooterDown";
import { Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
    const navigate = useNavigate()
    return (
        <div>
            <TopBarIndex />
            <div className="h-screen w-full">
                <div className="container mx-auto text-center h-full justify-center items-center flex flex-col  text-3xl p-4">
                    <Paper elevation={6}>
                        <div className="p-4 border ronded shadow-orange-100 rounded">
                            Operazione completata con successo!
                        </div>
                    </Paper>
                    <button
                    className={"mt-4 py-2 px-10 text-xl text-white bg-[#DF5B23] w-fit hover:opacity-80"}
                    style={{
                        fontFamily: "gimlet-text",
                        fontWeight: 500,
                        fontStyle: "italic",
                    }}
                    onClick={() => { navigate(localStorage.getItem('lastpage')) }}>
                        Torna Indietro
                    </button>
                </div>
            </div>
            <FooterTop />
            <FooterDown />
        </div>
    );
};

export default PaymentSuccess;
