import TopBarIndex from '../components/TopBarIndex';
import FooterDown from '../Sezioni/FooterDown';
import montagne1 from "../assets/img/footer-marrone.png";
import img1 from "../assets/img/accompagnatori.jpg";
import BackgroundTitle from '../components/BackgroundTitle';
import { Typography } from '@mui/material';
import env from '../conf/variables';



function CookiePolicy() {

    return (
        <>
            <TopBarIndex />
            <BackgroundTitle
                title={"Privacy"}
                img={img1}
                position={"center"}
            />
            <div className={" bg-white relative z-40 pt-20 flex flex-col justify-center items-center"}>
                <div className="w-[85%]">
                    <div
                        className={"flex flex-row items-center gap-1"}
                        style={{
                            fontFamily: "bilo",
                            fontWeight: 500,
                            fontStyle: "normal",
                        }}
                    >
                        <div>Associazione &gt; </div>
                        <div className={"font-bold"}>Privacy</div>
                    </div>
                    {/* Content */}
                    <div
                        className={"my-6 mb-[3.5rem] text-4xl text-[#DF5B23]"}
                        style={{
                            fontFamily: "gimlet-text",
                            fontWeight: 500,
                            fontStyle: "italic",
                        }}
                    >
                        Privacy
                    </div>
                    {/* <div className='my-12'>
                        <InputBilo
                            className={"text-xl font-bold"}
                            title="Privacy utente"
                        />
                        <div className="my-6 max-h-56 overflow-y-auto">
                            {Privacy()}
                        </div>
                        <InputBilo
                            className={"text-xl font-bold"}
                            title={`Accettata in data: ${user && user.data && user.data.data ? formattedDate(user.data.data.created_at) : ''} `}
                        />
                    </div>
                    {
                        user && user.data && user.data.data && user.data.data.user_partner === '1' &&
                        <div className='my-12'>
                            <InputBilo
                                className={"text-xl font-bold"}
                                title="Privacy socio"
                            />
                            <div className="my-6 max-h-56 overflow-y-auto">
                                {Privacy2()}
                            </div>
                            <InputBilo
                                className={"text-xl font-bold"}
                                title={`Accettata in data: ${user && user.data && user.data.data.associate ? formattedDate(user.data.data.associate.created_at) : ''} `}
                            />
                        </div>

                    } */}
                    <div className={"flex flex-col mb-4"}>
                        <Typography className={'!my-4'} component={"h1"}>Informativa – Cookies Policy</Typography>
                        <h1 className="text-2xl font-semibold mb-4">Informativa – Cookies Policy</h1>
                        <p>Questo sito web utilizza cookie e tecnologie simili per garantire il corretto funzionamento delle procedure e migliorare l’esperienza di uso delle applicazioni online. Il presente documento fornisce informazioni dettagliate sull’uso dei cookie e di tecnologie similari, su come sono utilizzati da www.trekkingitalia.org e su come gestirli.</p>
                        <p className="font-semibold my-4">COSA SONO I COOKIES?</p>
                        <p>I cookie sono brevi frammenti di testo (lettere e/o numeri) che permettono al server web di memorizzare sul client (il browser) informazioni da riutilizzare nel corso della medesima visita al sito (cookie di sessione) o in seguito, anche a distanza di giorni (cookie persistenti). I cookie vengono memorizzati, in base alle preferenze dell’utente, dal singolo browser sullo specifico dispositivo utilizzato (computer, tablet, smartphone).</p>
                        <p>Tecnologie similari, come, ad esempio, web beacon, GIF trasparenti e tutte le forme di storage locale introdotte con HTML5, sono utilizzabili per raccogliere informazioni sul comportamento dell’utente e sull’utilizzo dei servizi.</p>
                        <p>Nel seguito di questo documento faremo riferimento ai cookie e a tutte le tecnologie similari utilizzando semplicemente il termine “cookie”.</p>
                        <p className="font-semibold my-4">TIPOLOGIA DI COOKIES:</p>
                        <ul className='list-disc pl-10'>
                            <li>
                                <strong>Cookie strettamente necessari</strong>. Si tratta di cookie indispensabili per il corretto funzionamento del sito web e sono utilizzati per gestire il login e l’accesso alle funzioni riservate del sito. La durata dei cookie è strettamente limitata alla sessione di lavoro (chiuso il browser vengono cancellati). La loro disattivazione compromette l’utilizzo dei servizi accessibili da login.
                            </li>
                            <li>
                                <strong>Cookie di analisi e prestazioni</strong>. Sono cookie utilizzati per raccogliere e analizzare il traffico e l’utilizzo del sito in modo anonimo. Questi cookie, pur senza identificare l’utente, consentono, per esempio, di rilevare se il medesimo utente torna a collegarsi in momenti diversi. Permettono inoltre di monitorare il sistema e migliorarne le prestazioni e l’usabilità. La disattivazione di tali cookie può essere eseguita senza alcuna perdita di funzionalità.
                            </li>
                            <li>
                                <strong>Cookie di profilazione</strong>. Si tratta di cookie permanenti utilizzati per identificare (in modo anonimo e non) le preferenze dell’utente e migliorare la sua esperienza di navigazione.
                            </li>
                        </ul>
                        <p className="font-semibold my-4">COOKIES DI TERZE PARTI:</p>
                        <p>Visitando un sito web si possono ricevere cookie sia dal sito visitato (“proprietari”), sia da siti gestiti da altre organizzazioni (“terze parti”). Un esempio notevole è rappresentato dalla presenza dei “social plugin” per Facebook, Twitter, Google+ e LinkedIn. Si tratta di parti della pagina visitata generate direttamente dai suddetti siti ed integrati nella pagina del sito ospitante. L’utilizzo più comune dei social plugin è finalizzato alla condivisione dei contenuti sui social network.</p>
                        <p>La presenza di questi plugin comporta la trasmissione di cookie da e verso tutti i siti gestiti da terze parti. La gestione delle informazioni raccolte da “terze parti” è disciplinata dalle relative informative cui si prega di fare riferimento. Per garantire una maggiore trasparenza e comodità, si riportano qui di seguito gli indirizzi web delle diverse informative e delle modalità per la gestione dei cookie.</p>
                        <p><a style={{ color: "#e7501e" }} href="https://www.facebook.com/help/cookies/" target="_blank" rel="noopener noreferrer">Facebook informativa</a><br />
                            Facebook (configurazione): accedere al proprio account. Sezione privacy.</p>
                        <p><a style={{ color: "#e7501e" }} href="http://www.google.it/intl/it/policies/technologies/cookies/" target="_blank" rel="noopener noreferrer">Google+ informativa</a><br />
                            <a style={{ color: "#e7501e" }} href="http://www.google.it/intl/it/policies/technologies/managing/">Google+ (configurazione)</a></p>
                        <p className="font-semibold my-4">GOOGLE ANALYTICS</p>
                        <p>Questo sito web include anche talune componenti trasmesse da Google Analytics, un servizio di analisi del traffico web fornito da Google, Inc. (“Google”). Anche in questo caso si tratta di cookie di terze parti raccolti e gestiti in modo anonimo per monitorare e migliorare le prestazioni del sito ospite (performance cookie).</p>
                        <p>Google Analytics utilizza i “cookie” per raccogliere e analizzare in forma anonima le informazioni sui comportamenti di utilizzo di questo sito web (compreso l’indirizzo IP dell’utente). Tali informazioni vengono raccolte da Google Analytics, che le elabora allo scopo di redigere report per i gestori del sito riguardanti le attività sullo stesso. Questo sito non utilizza (e non consente a terzi di utilizzare) lo strumento di analisi di Google per monitorare o per raccogliere informazioni personali di identificazione. Google non associa l’indirizzo IP a nessun altro dato posseduto da Google né cerca di collegare un indirizzo IP con l’identità di un utente. Google può anche comunicare queste informazioni a terzi ove ciò sia imposto dalla legge o laddove tali terzi trattino le suddette informazioni per conto di Google.</p>
                        <p>Per ulteriori informazioni, si rinvia al link di seguito indicato:<br />
                            <a style={{ color: "#e7501e" }} href="https://www.google.it/policies/privacy/partners/" target="_blank" rel="noopener noreferrer">https://www.google.it/policies/privacy/partners/</a></p>
                        <p>L’utente può disabilitare in modo selettivo l’azione di Google Analytics installando sul proprio browser la componente di opt-out fornito da Google. Per disabilitare l’azione di Google Analytics, si rinvia al link di seguito indicato:<br />
                            <a style={{ color: "#e7501e" }} href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout</a></p>
                        <p className="font-semibold my-4">DURATA DEI COOKIES</p>
                        <p>Alcuni cookie (cookie di sessione) restano attivi solo fino alla chiusura del browser o all’esecuzione del comando di logout. Altri cookie “sopravvivono” alla chiusura del browser e sono disponibili anche in successive visite dell’utente.<br />
                            Questi cookie sono detti persistenti e la loro durata è fissata dal server al momento della loro creazione. In alcuni casi è fissata una scadenza, in altri casi la durata è illimitata.</p>
                        <p className="font-semibold my-4">GESTIONE DEI COOKIE</p>
                        <p>L’utente può decidere se accettare o meno i cookie utilizzando le impostazioni del proprio browser.</p>
                        <p className="font-semibold my-4">Attenzione: la disabilitazione totale o parziale dei cookie tecnici può compromettere l’utilizzo delle funzionalità del sito riservate agli utenti registrati. Al contrario, la fruibilità dei contenuti pubblici è possibile anche disabilitando completamente i cookie.<br />
                            La disabilitazione dei cookie “terze parti” non pregiudica in alcun modo la navigabilità.<br />
                            L’impostazione può essere definita in modo specifico per i diversi siti e applicazioni web. Inoltre i migliori browser consentono di definire impostazioni diverse per i cookie “proprietari” e per quelli di “terze parti”.</p>
                        <p>A titolo di esempio, in Firefox, attraverso il menu Strumenti-&gt;Opzioni -&gt;Privacy, è possibile accedere ad un pannello di controllo dove è possibile definire se accettare o meno i diversi tipi di cookie e procedere alla loro rimozione.</p>
                        <p><a style={{ color: "#e7501e" }} href="https://support.google.com/chrome/answer/95647?hl=it" target="_blank" rel="noopener noreferrer">Chrome</a><br />
                            <a style={{ color: "#e7501e" }} href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie" target="_blank" rel="noopener noreferrer">Firefox</a><br />
                            <a style={{ color: "#e7501e" }} href="http://windows.microsoft.com/it-it/windows7/how-to-manage-cookies-in-internet-explorer-9" target="_blank" rel="noopener noreferrer">Internet Explorer</a><br />
                            <a style={{ color: "#e7501e" }} href="http://help.opera.com/Windows/10.00/it/cookies.html" target="_blank" rel="noopener noreferrer">Opera</a><br />
                            <a style={{ color: "#e7501e" }} href="http://support.apple.com/kb/HT1677?viewlocale=it_IT" target="_blank" rel="noopener noreferrer">Safari</a></p>
                        <p>Per maggiori informazioni, visita la pagina&nbsp;<a style={{ color: "#e7501e" }} href="http://www.youronlinechoices.eu/" target="_blank" rel="noopener noreferrer">www.youronlinechoices.eu</a></p>
                        <p className="font-semibold my-4">WHAT ARE COOKIES?</p>
                        <p>Cookies contain information that is stored on the browser of your computer or mobile devices hard drive. We use cookies to provide a browsing experience to you.</p>
                        <p className="font-semibold my-4">WHAT TYPE OF COOKIES DO WE USE?</p>
                        <p>Session cookies are deleted from your computer when you close your web-browser. Persistent cookies will remain stored on your computer until deleted or until they reach their expiry date.</p>
                        <p>We use the following cookies:&nbsp;<strong>Analytical/performance cookies.</strong></p>
                        <p>These cookies allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works.</p>
                        <h1 className="font-semibold my-4">WHAT KIND OF INFORMATION DO WE COLLECT BY USING COOKIES?</h1>
                        <p>When you visit our website, we may automatically collect the following types of information from you: Your internet protocol (IP) address, time zone setting, operating system and platform, information about your visits including the URL you came from, your country, page response times, download errors, length of visits to certain pages, page interaction information, (such as scrolling, clicks, and mouse-overs) and the methods used to browse away from the page. However, most browsers allow you to refuse cookies. You may block our cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. You can find out more about cookies and how to delete and control them on www.aboutcookies.org or click help in your browser menu.</p>
                        <p>v.{env.version}</p>
                    </div>
                </div>
            </div>
            <div className={"2xl:hidden flex flex-col items-center gap-2 bg-[#f0e9e3] mt-8 py-8 relative"}>
                <img
                    src={montagne1}
                    alt={"img"}
                    className={"object-contain absolute z-30 -bottom-3"}
                />
            </div>
            <FooterDown />

        </>
    );
}

export default CookiePolicy