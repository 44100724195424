import { useGeolocated } from "react-geolocated";
import TrekItalia from "./TrekItalia";

function TrekGeo() {

    const { coords } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });


    return (
       <TrekItalia pos="Geolocalizzato" coords={coords}/>
    );
}

export default TrekGeo